import React from 'react';
import PropTypes from 'prop-types';
import { Row, Column } from '../Grid';
import Section from '../Section';
import Image from '../Image';

import './styles.scss';

const Quote = ({ quote, name, title, company, img, className }) => {
  return (
    <Section className={`quote ${className}`}>
      <Row>
        <Column large={5} className="quote__copy-column">
          <blockquote>“{quote}”</blockquote>
          <cite>
            {name}
            <span>
              {title}
              <br></br>
              {company}
            </span>
          </cite>
        </Column>
        <Column large={7} className="home-modules__image-column">
          <Image filename={`${img}`} className="home-modules__img" />
        </Column>
      </Row>
    </Section>
  );
};

Quote.propTypes = {
  /** The image's file name */
  img: PropTypes.string.isRequired,
  /** The quote copy */
  quote: PropTypes.string.isRequired,
  /** add className to quote */
  className: PropTypes.string,
};

export default Quote;
