import classNames from 'classnames';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Button from '../Button';
import Icon from '../Icon';
import Modal from '../Modal';
import Video from '../Video';

import './styles.scss';

const Action = ({
  label,
  metadata,
  module,
  moduleName,
  link,
  download,
  dropdown,
  noIcon,
  asLink,
  index,
  actions,
  modalButtonText,
  modalGuid,
  ...other
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const handleDropdownClick = () => setDropdownOpen((state) => !state);

  const renderModuleLink = () => {
    return (
      <Button
        to={module}
        target="_blank"
        forceExternal
        data-click-type="module"
        data-click-name={moduleName}
        className="resource__action-link"
      >
        {!noIcon && (
          <Icon
            marginRight
            name="externallink"
            className="resource__action-icon"
          />
        )}
        {label}
      </Button>
    );
  };

  const renderLink = () => {
    return asLink ? (
      <Link to={link} className="resource__action-link">
        {!noIcon && (
          <Icon marginRight name="module" className="resource__action-icon" />
        )}
        {label}
      </Link>
    ) : (
      <Button to={link} className="resource__action-link" {...other}>
        {!noIcon && (
          <Icon
            marginRight
            name="externallink"
            className="resource__action-icon"
          />
        )}
        {label}
      </Button>
    );
  };

  const renderDownload = () => {
    return (
      <Button
        to={download}
        target="_blank"
        rel="noopener noreferrer"
        className="resource__action-link"
        link={asLink}
      >
        {!noIcon && (
          <Icon name="download" className="resource__action-icon" marginRight />
        )}
        {label}
      </Button>
    );
  };

  const renderDropDown = () => (
    <Button onClick={handleDropdownClick}>
      <Icon
        name="caratdown"
        marginRight
        className={classNames(
          'resource__dropdown-carat',
          dropdownOpen && 'resource__dropdown-carat--open'
        )}
      />
      {label}
    </Button>
  );

  const renderModal = () => (
    <Modal
      trigger={
        <Button className="button">
          <Icon name="externallink" marginRight />
          {label}
        </Button>
      }
      title="Introvideo Modal"
    >
      <Video guidSrc={modalGuid} title="Discover Sustainable Futures" />
    </Modal>
  );

  return (
    <div className="resource__action">
      {link && renderLink()}
      {module && renderModuleLink()}
      {download && renderDownload()}
      {dropdown && renderDropDown()}
      {modalGuid && renderModal()}
      {metadata && <span className="resource__metadata">{metadata}</span>}

      {dropdown && dropdownOpen && (
        <div className="resource__dropdown-items">
          {actions.map((action, i) => (
            <Action {...action} key={i} />
          ))}
        </div>
      )}
    </div>
  );
};

Action.propTypes = {
  label: PropTypes.node.isRequired,
  metadata: PropTypes.string,
  module: PropTypes.string,
  download: PropTypes.string,
  noIcon: PropTypes.bool,
  asLink: PropTypes.bool,
};

export default Action;
