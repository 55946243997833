import React, {useState, useEffect} from 'react';
import Image from '../Image';
import './styles.scss';
import PropTypes from 'prop-types';
import { Row, Column, Container } from '../Grid';
import Section from '../Section';

const Hero = ({ mediaSourceArray, textOverlay}) => {

  const [currentImage, setCurrentImage] = useState(mediaSourceArray[0]);
  let count = 0;
  
  useEffect(() => {
    count++
    
    const intervalId = setInterval(() => {
      if (count < mediaSourceArray.length){
        setCurrentImage(mediaSourceArray[count]);
        count++
      } else if (count >= mediaSourceArray.length){
        setCurrentImage(mediaSourceArray[0]);
        count = 1;
      }
    }, 4000)
    
    return () => clearInterval(intervalId);
}, [])

  return (
    <Section className={"hero-carousel"}>
      <Container>
        <Row>
          <Column medium={5} className="hero-carousel__copy">
            <div className="hero-carousel__logo">
              <Image filename={"sustainable-futures.png"}  />
            </div>
            <h2>
              {textOverlay}
            </h2>
          </Column>
          <Column medium={7} className="hero-carousel__img-bkg">
            <Image filename={currentImage} className="hero-carousel__img hero-carousel__fade-in" />
          </Column>
        </Row>
      </Container>
    </Section>
  );
};

Hero.defaultProps = {
  textOverlay: null,
  className: null,
};

Hero.propTypes = {

  textOverlay: PropTypes.string,
  /** A custom class name */
  classNames: PropTypes.string,
};

export default Hero;
