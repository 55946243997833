import React from 'react';
import Button from '../components/Button';
import { Column, Container, Row } from '../components/Grid';
import HeroCarousel from '../components/HeroCarousel';
import Icon from '../components/Icon';
import Image from '../components/Image';
import Layout from '../components/Layout';
import Modal from '../components/Modal';
import Quote from '../components/Quote';
import Resource from '../components/Resource';
import Section from '../components/Section';
import Video from '../components/Video';

const IndexPage = ({ data }) => {
  return (
    <Layout title="Home">
      <HeroCarousel
        className={'mb-1'}
        mediaSourceArray={[
          'img-hero-hmpg-1.jpg',
          'img-hero-hmpg-2.jpg',
          'img-hero-hmpg-3.jpg',
        ]}
        textOverlay={
          'Ideas and inspirations to help students change the world '
        }
      />
      {/* <Callout to="/virtual-field-trip">
      Watch the Virtual Field Trip
    </Callout> */}
      <Section className={'pt-1'}>
        <Container>
          <Row>
            <Column size={9} className="home-modules__image-column">
              <p>
                Discovery Education and Trane Technologies have partnered to
                present <strong>Sustainable Futures</strong> – resources to
                inspire students to learn, explore, innovate and lead us to a
                healthier world. <strong>Sustainable Futures</strong> equips
                educators with interactive classroom tools that connect
                important real-world sustainability goals to everyday challenges
                students care about. It helps students see how they can get
                involved in creating solutions, takes them into the field for
                up-close STEM explorations, and introduces them to a variety of
                green career pathways.
              </p>
            </Column>
            <Column size={3}>
              <Image
                filename={'icon_hmpg_aboutProgram.jpg'}
                className="home__image-column"
              />
            </Column>
          </Row>
        </Container>
      </Section>
      <Section className={'pt-1'}>
        <Container>
          <Row>
            <Column size={8}>
              <div className="home__vft-image">
                <Image
                  filename="img_home_promovideo.jpg"
                  className="home-about__img"
                />
              </div>
            </Column>
            <Column size={4} className="bg-color--gray column-right">
              <div className="home__register-vft">
                <Image
                  filename="home-learnmore-flag@2x.png"
                  className="home__learnmore-flag"
                />
                <h3 className="pt-2 home__learnmore-h3">
                  Discover Sustainable Futures
                </h3>
                <p>
                  Learn more about our interactive resources designed to get
                  students thinking big and taking action on critical
                  sustainability challenges facing our world.
                </p>
                <Modal
                  trigger={
                    <Button className="button uppercase">
                      <Icon name="externallink" marginRight />
                      Watch Now
                    </Button>
                  }
                  title="Introvideo Modal"
                >
                  <Video
                    guidSrc="2b76ec78-5c87-4688-9352-8d0ab76b9937"
                    title="Discover Sustainable Futures"
                  />
                </Modal>
              </div>
            </Column>
          </Row>
        </Container>
      </Section>
      <Section>
        <Container>
          <Row>
            <h2>Explore these great resources!</h2>
          </Row>
          <Row>
            <Column size={6}>
              <Resource
                img="Home-image-SFvft.jpg"
                title="THE SUSTAINABLE FUTURES VIRTUAL FIELD TRIP"
                description="Inspire students to be future sustainability leaders by taking them behind the scenes to meet innovators developing climate solutions that are creating a more sustainable world."
                actions={[
                  {
                    label: 'Watch Now',
                    link: '/virtual-field-trip',
                  },
                ]}
                className="h2__purple"
              />
            </Column>
            <Column size={6}>
              <Resource
                img="img-home-vts@2x.jpg"
                title='WATCH "SUSTAINABILITY: THE HUMAN FACTOR"'
                description="Celebrate students’ power to create change in their communities with a 3–part Video Topic Series highlighting passionate young individuals who are putting sustainable science into action. Extend learning with companion activities for classrooms and families."
                actions={[
                  {
                    label: 'Watch Now',
                    link: '/educator-resources/video-topic-series',
                  },
                ]}
                className="h2__purple"
              />
            </Column>
          </Row>
        </Container>
      </Section>
      <Section>
        <Container>
          <Row>
            <Column size={6}>
              <Resource
                img="img-home-dlb@2x.jpg"
                title="DIGITAL LESSON BUNDLE"
                description="Explore the ways that students’ environment impacts their lives with this hands-on learning exploration and inspire students to investigate Indoor Environmental Quality (IEQ) in their own community."
                actions={[
                  {
                    label: 'Learn More',
                    link: '/educator-resources/digital-lesson-bundles',
                  },
                ]}
                className="h2__purple"
              />
            </Column>
            <Column size={6}>
              <Resource
                img="img_home_ExploreSTEM.jpg"
                title="EXPLORE STEM CAREERS"
                description="Find out how a passion for problem-solving can be used to build a successful career that positively impacts the lives of others. Go on the job with the diverse team at Trane Technologies."
                actions={[
                  {
                    label: 'Learn More',
                    link: '/stem-careers',
                  },
                ]}
                className="h2__purple"
              />
            </Column>
          </Row>
        </Container>
      </Section>
      <Section>
        <Container>
          <Row>
            <Column size={6}>
              <Resource
                img="img-home-familyresources@2x.jpg"
                title="FAMILY RESOURCES"
                description="Explore the ways that students’ local environment impacts their lives with these hands-on  interactive lessons that will inspire them to come up with solutions of their own for improving Indoor Environmental Quality (IEQ) in their own community, and homes."
                actions={[
                  {
                    label: 'Learn More',
                    link: '/family-resources',
                  },
                ]}
                className="h2__purple"
              />
            </Column>
            <Column size={6}>
              <Resource
                img="about-thumbnail-impactvideo.jpg"
                title="SUSTAINABLE FUTURES: MAKING AN IMPACT"
                description="Learn how employees at the global climate leader, Trane Technologies are heading into schools with their Sustainable Futures program to empower students like yours to be tomorrow’s change-makers."
                actions={[
                  {
                    label: 'Watch Now',
                    modalGuid: 'cef13352-fa99-49d9-b472-359c24fb2998',
                  },
                ]}
                className="h2__purple"
              />
            </Column>
          </Row>
        </Container>
      </Section>
      <Section>
        <Container>
          <Row>
            <Column size={6}>
              <h2 className="home__heading">
                About the Sustainable Futures Virtual Field Trip
              </h2>
              <h4>Reimagining Today for a More Sustainable Tomorrow</h4>
              <p>
                Building a more sustainable world can feel like a huge goal –
                and it is, but it’s possible with bold, decisive action. The
                people at Trane Technologies will show you how in their
                Sustainable Futures Virtual Field Trip, which challenges
                students to explore how they too can think big, challenge what’s
                possible and create a more sustainable future. Students will be
                transported onto Trane Technologies’ campuses where they’ll meet
                diverse and dynamic professionals who will excite their
                creativity with real-life examples of out-of-the-box thinking
                that are creating a lasting, positive impact on our planet.
              </p>
              <p>
                Show students how they can start living more sustainably today,
                while also helping them understand important topics affecting
                the world around them, like climate change, and food waste and
                insecurity.
              </p>
            </Column>
            <Column size={6} className="home-modules__image-column">
              <Image
                filename="img_hmpg_aboutVFT.png"
                className="home-modules__img"
              />
            </Column>
          </Row>
        </Container>
      </Section>
      <Section className="home__topics">
        <Container>
          <h3>KEY TOPICS</h3>
          <Row>
            <Column medium={4}>
              <div className="home__topics-card">
                <Image filename={'icon-hmpg-topicbox1.jpg'} />
                <div className="home__topics-copy">
                  <h3>Personal Choices with Global Consequences</h3>
                  <h3>
                    Explore impacts of everyday activities on the environment
                  </h3>
                  <p>
                    Make sustainability real by giving students a practical view
                    of the current state of the Earth’s environment and how
                    everyday behaviors impact our climate.
                  </p>
                  <ul>
                    <li>Context on Climate Change</li>
                    <li>Carbon Footprint</li>
                    <li>Technology’s Impact on the Environment</li>
                  </ul>
                </div>
              </div>
            </Column>
            <Column medium={4}>
              <div className="home__topics-card">
                <Image
                  filename={'icon-hmpg-topicbox2.jpg'}
                  className="home__topics-padding"
                />
                <div className="home__topics-copy">
                  <h3>Innovating for the Future</h3>
                  <h3>
                    An up-close look at how sustainable innovation is protecting
                    our planet
                  </h3>
                  <p>
                    Get inspired by real-world professionals who are using
                    sustainable solutions to solve critical climate challenges.
                  </p>
                  <ul>
                    <li>Sustainability Subject Matter Experts</li>
                    <li>Tackling Food Waste and Healthy Indoor Air</li>
                    <li>Achieving Big Goals with Bold Actions</li>
                  </ul>
                </div>
              </div>
            </Column>
            <Column medium={4}>
              <div className="home__topics-card">
                <Image
                  filename={'icon-hmpg-topicbox3.jpg'}
                  className="home__topics-padding"
                />
                <div className="home__topics-copy">
                  <h3>Taking Informed Action</h3>
                  <h3>Inspire students to live more sustainably</h3>
                  <p>
                    Inspire students to take action in their own communities
                    with engineering design opportunities.
                  </p>
                  <ul>
                    <li>Looking at Life Through a Sustainability Lens</li>
                    <li>Embracing Everyday Action</li>
                    <li>Goal Setting and Problem Solving</li>
                  </ul>
                </div>
              </div>
            </Column>
          </Row>
        </Container>
      </Section>
      {/* <Section>
        <Container>
          <a
            href="https://surveys.discoveryeducation.com/s3/Sustainable-Futures-2023"
            target="_blank"
          >
            <Callout>
              Let us know what you think about Sustainable Futures
              <Icon name="arrowright" />
            </Callout>
          </a>
        </Container>
      </Section> */}
      <Quote
        quote={
          'This planet is home to us all, but to young people especially. Their passion and creativity is critical in the fight for a healthy future.'
        }
        img={'img-hmpg-testimonial-hero.jpg'}
        name={'Deidra Parrish Williams'}
        title={'Trane Technologies'}
        className={'quote--vft mt-2'}
      />
    </Layout>
  );
};

export default IndexPage;
